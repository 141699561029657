.footer {
	&__content {
		background-color: $blue;
		padding-bottom: 40px;
	}

	&__nav {
		display: flex;
		gap: 60px;
		flex-wrap: wrap;
		padding-top: 60px;
		padding-bottom: 40px;
	}

	&-item {
		&:last-child {
			margin-left: auto;

			@include tablet {
				margin-left: 0;
				order: -1;
			}
		}
		&__header {
			margin-bottom: 12px;
		}

		&__title {
			color: $white;
			opacity: 0.6;
			font-size: 12px;
			font-weight: 400;
		}

		&__link {
			display: block;
			color: $white;
			font-size: 14px;
			font-weight: 400;

			&:not(:last-child) {
				margin-bottom: 8px;
			}

			&:hover {
				text-decoration: underline;
			}

			&-logo {
				height: 28px;
			}
		}

		&__contact {
			&-title {
				color: $white;
				font-size: 16px;
				font-weight: 700;
			}

			&-subtitle {
				font-size: 12px;
				font-weight: 300;
				color: $white;

				margin-bottom: 8px;
			}

			&-item {
				display: flex;
				align-items: center;

				&:not(:last-child) {
					margin-bottom: 8px;
				}

				&-icon {
					width: 29px;
					height: 29px;
					margin-right: 12px;
				}

				&-link {
					color: $white;
					font-size: 20px;
					font-weight: 600;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__copywrite {
		color: $white;
		font-size: 12px;
		font-weight: 300;
	}

	&__logo {
		filter: drop-shadow(rgba(0, 0, 0, 0.1) 2px -6px 6px);

		.logo {
			padding-left: 20px;
			background-color: $blue;
			height: 68px;
			max-width: 300px;
			clip-path: polygon(82.778% 33.838%, 82.778% 33.838%, 81.79% 27.663%, 80.726% 22.057%, 79.593% 17.041%, 78.397% 12.633%, 77.146% 8.851%, 75.846% 5.715%, 74.505% 3.243%, 73.129% 1.454%, 71.726% 0.367%, 70.301% 0, 0 0, 0 100%, 100% 100%, 100% 100%, 98.576% 99.633%, 97.172% 98.546%, 95.797% 96.757%, 94.455% 94.285%, 93.156% 91.149%, 91.905% 87.367%, 90.709% 82.959%, 89.576% 77.943%, 88.511% 72.337%, 87.523% 66.162%, 82.778% 33.838%);
		}
	}
}
