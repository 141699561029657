$blue: #2454e9;
$black: #212224;
$white: #ffffff;

$blue-main-gradient: linear-gradient(90deg, #1f50e8 0%, #5379f0 100%);

$red: #ee614d;
$green: #55cd6c;
$darken-gray: #686e78;
$dark-gray: #a1a8b4;
$gray: #cbcfd6;
$light-gray: #f0f0f4;
$dark-black: #18191b;

$light-black: #3b3e43;

$mobile-small: 320px;
$mobile: 480px;
$tablet: 768px;
$laptop-small: 1024px;
$laptop: 1248px;
$desktop: 1366px;
