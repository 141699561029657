.review {
	margin-bottom: 80px;

	@include tablet {
		margin-bottom: 40px;
	}

	&__header {
		margin-bottom: 20px;

		@include tablet {
			margin-bottom: 4px;
		}
	}

	&__title {
		text-align: center;
	}

	&__slider.swiper {
		padding-bottom: 40px;
		padding-top: 20px;

		.swiper-slide {
			height: auto;
		}

		.swiper-button {
			&-prev {
				left: 10px;
			}

			&-next {
				right: 10px;
			}

			&-prev,
			&-next {
				padding: 10px 24px;
				transform: translateY(-50%);
				margin-top: 0px;
				background-color: $white;
				border-radius: 10px;
				border: 1px solid $blue;

				@include tablet {
					height: 28px;
					padding: 6px 14px;
					transform: translateY(0);
					top: auto;
					bottom: 2px;
				}

				&:hover {
					background-color: $blue;

					&::after {
						color: $white;
					}
				}

				&::after {
					font-size: 24px;

					@include tablet {
						font-size: 12px;
					}
				}
			}
		}
	}
}

.review-card {
	display: flex !important;
	flex-direction: column;
	gap: 16px;
	max-width: 360px;
	width: 100%;
	padding: 24px;
	border-radius: 16px;
	background: $white;
	box-shadow: 0px 3px 13.9px 0px rgba(30, 60, 117, 0.16);

	@include tablet {
		max-width: 320px;
	}

	&__prof,
	&__info-item,
	&__date,
	&__footer-prof {
		color: $darken-gray;
		font-size: 12px;
		font-weight: 400;
	}

	&__info {
		&-item {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}

		&-accent {
			margin-left: 4px;
			color: #2454e9;
			font-size: 16px;
			font-weight: 500;
		}
	}

	&__name,
	&__footer-name,
	&__footer-rating-value {
		color: $black;
		font-size: 16px;
		font-weight: 500;
	}

	&__footer-rating-value {
		font-weight: 700;
	}

	&__main {
		&-text {
			color: $black;
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 4px;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		margin-top: auto;

		&-img {
			width: 50px;
			height: 50px;
			margin-right: 12px;
		}

		&-rating {
			display: flex;
			align-items: center;

			&-icon {
				margin-right: 4px;
			}
		}
	}
}
