.promo-line {
	background: $blue-main-gradient;
	padding-top: 6px;
	padding-bottom: 6px;

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
	}

	&__text {
		color: $white;
		font-size: 16px;
		font-weight: 300;

		@include tablet {
			font-size: 12px;
		}
	}

	&__button {
		font-size: 12px;
		color: $white;
		border: 1px solid $white;
		flex-shrink: 0;
	}
}
