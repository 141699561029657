.cta {
	&-video {
		margin-bottom: 80px;

		@include tablet {
			margin-bottom: 40px;
		}

		&__wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 32px;
			// flex-wrap: wrap;

			@include tablet {
				flex-direction: column;
				justify-content: center;
			}
		}

		&__main {
			max-width: 550px;
		}

		&__content {
			@include laptop-small {
				width: 100%;
				max-width: 400px;
			}
		}

		&__subtitle {
			font-size: 16px;
			font-weight: 400;
			color: $black;
			margin-bottom: 24px;

			@include tablet {
				margin-bottom: 16px;
				font-size: 14px;
				text-align: center;
			}

			&-brand {
				background: $blue-main-gradient;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: rgba(0, 0, 0, 0);
				font-size: 24px;
				font-weight: 600;
				margin-bottom: 12px;

				@include tablet {
					font-size: 18px;
				}
			}
		}

		&__button {
			@include tablet {
				position: relative;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		&__video-wrapper {
			width: 480px;
			border-radius: 30px;
			overflow: hidden;
			// height: 454px;

			@include laptop-small {
				width: 100%;
			}
		}
	}
}
