.contacts {
	margin-top: 60px;
	margin-bottom: 80px;

	@include tablet {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	&__header {
		margin-bottom: 40px;

		@include tablet {
			margin-bottom: 24px;
		}
	}

	&__title {
		text-align: center;
		margin-bottom: 24px;

		@include tablet {
			margin-bottom: 12px;
		}
	}

	&__subtitle {
		text-align: center;
		max-width: 600px;
		margin: 0 auto;
	}

	&__contact {
		display: flex;
		flex-direction: column;
		align-items: center;

		&-item {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin-bottom: 16px;
			}

			&-icon {
				width: 29px;
				height: 29px;
				margin-right: 12px;
			}

			&-link,
			&-title {
				color: $black;
				font-size: 20px;
				font-weight: 600;
				text-align: center;
			}

			&-link {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
