.go-back {
	margin-top: 60px;
	margin-bottom: 80px;

	@include tablet {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	&__link {
		display: flex;
		align-items: center;
		gap: 8px;

		color: $blue;
		font-size: 14px;
	}
}
