.title {
	color: $black;

	&-h1 {
		font-size: 28px;
		font-weight: 700;

		@include tablet {
			font-size: 26px;
		}
	}
	&-h2 {
		font-size: 36px;
		font-weight: 500;

		@include tablet {
			font-size: 26px;
		}
	}

	&-h3 {
		font-size: 24px;
		font-weight: 500;

		@include tablet {
			font-size: 18px;
		}
	}

	&-h4 {
		font-size: 20px;
		font-weight: 500;

		@include tablet {
			font-size: 16px;
		}
	}

	&--color {
		&_black {
			color: $black;
		}
	}
}
