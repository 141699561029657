.button {
	display: inline-block;
	border-radius: 0.75rem;
	border: 1px solid transparent;
	text-align: center;

	transition: all 0.4s;

	&--size {
		&_lg {
			font-size: 1.125rem;
			padding-bottom: 1rem;
			padding-top: 1rem;
			padding-left: 2.25rem;
			padding-right: 2.25rem;

			@include tablet {
				font-size: 0.95rem;
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}

		&_sm {
			padding-bottom: 0.75rem;
			padding-top: 0.75rem;
			padding-left: 2.25rem;
			padding-right: 2.25rem;
		}

		&_micro {
			padding-bottom: 0.5rem;
			padding-top: 0.5rem;
			padding-left: 1.25rem;
			padding-right: 1.25rem;
		}
	}

	&--color {
		&_main-gradient {
			background: $blue-main-gradient;
			color: $white;

			&:hover {
				border-color: $blue;
				background: $white;
				color: $blue;
			}
		}
	}
}
