.logo {
	display: flex;
	align-items: center;

	&_white {
		.logo__content {
			color: $white;
		}
	}

	&__content {
		color: $blue;
	}

	&__icon {
		margin-right: 12px;
	}

	&__text {
		display: block;

		&-main {
			font-size: 14px;
			font-weight: 600;
		}

		&-add {
			font-size: 12px;
		}
	}
}
