.header {
	box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px -3px 10px 0px inset;
	background-color: $white;

	&__wrapper {
		padding-left: 0;
		padding-right: 20px;
	}

	&.sticky {
		position: sticky;
		top: 0;
		z-index: 1000;
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__logo {
		filter: drop-shadow(rgba(0, 0, 0, 0.1) 2px -6px 6px);

		.logo {
			padding-left: 20px;
			background-color: $white;
			height: 68px;
			min-width: 270px;
			clip-path: polygon(82.778% 33.838%, 82.778% 33.838%, 81.79% 27.663%, 80.726% 22.057%, 79.593% 17.041%, 78.397% 12.633%, 77.146% 8.851%, 75.846% 5.715%, 74.505% 3.243%, 73.129% 1.454%, 71.726% 0.367%, 70.301% 0, 0 0, 0 100%, 100% 100%, 100% 100%, 98.576% 99.633%, 97.172% 98.546%, 95.797% 96.757%, 94.455% 94.285%, 93.156% 91.149%, 91.905% 87.367%, 90.709% 82.959%, 89.576% 77.943%, 88.511% 72.337%, 87.523% 66.162%, 82.778% 33.838%);
		}
	}

	&__nav {
		width: 100%;

		@include laptop-small {
			display: none;
			align-items: center;
			background-color: #ffffffef;

			height: 100vh;

			left: 0;
			opacity: 0;
			position: fixed;
			top: 0;
			z-index: 30;

			&.open {
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: 1;
			}
		}

		&-list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 1rem;
			list-style: none;

			@include laptop-small {
				flex-direction: column;
				gap: 2rem;
				justify-content: center;
			}

			&-link {
				font-size: 16px;
				font-weight: 400;
				color: $black;

				&:hover {
					text-decoration: underline;
				}

				@include laptop-small {
					font-size: 24px;
				}
			}
		}
	}

	&__burger {
		display: none;
		width: 30px;
		height: 21px;
		position: relative;
		transform: rotate(0deg);
		transition: 0.5s ease-in-out;
		cursor: pointer;
		z-index: 35;

		@include laptop-small {
			display: block;
		}

		span {
			display: block;
			position: absolute;
			height: 3px;
			width: 100%;
			background: $blue;
			border-radius: 4px;
			opacity: 1;
			left: 0;

			transform: rotate(0deg);

			transition: 0.25s ease-in-out;

			&:nth-child(1) {
				top: 0px;
			}
			&:nth-child(2),
			&:nth-child(3) {
				top: 9px;
			}
			&:nth-child(4) {
				bottom: 0px;
			}
		}

		&.open {
			span {
				&:nth-child(1) {
					top: 18px;
					width: 0%;
					left: 50%;
				}
				&:nth-child(2) {
					transform: rotate(45deg);
				}
				&:nth-child(3) {
					transform: rotate(-45deg);
				}
				&:nth-child(4) {
					top: 18px;
					width: 0%;
					left: 50%;
				}
			}
		}
	}
}
