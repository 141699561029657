.service {
	margin-bottom: 80px;

	@include tablet {
		margin-bottom: 40px;
	}

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 24px;

		&_reverse {
			flex-direction: row-reverse;
		}

		@include tablet {
			flex-direction: column;
		}
	}

	&__main {
		max-width: 550px;
	}

	// &__content {
	// 	flex: 1 1 30%;

	// 	@include tablet {
	// 		max-width: 360px;
	// 	}
	// }

	&__content {
		@include laptop-small {
			width: 100%;
			max-width: 400px;
		}
	}

	&__video-wrapper {
		width: 530px;
		border-radius: 30px;
		overflow: hidden;
		// height: 454px;

		@include laptop-small {
			width: 100%;
		}
	}

	&__title {
		margin-bottom: 16px;
	}

	&__list {
		padding-left: 18px;

		&-item {
			font-size: 16px;
			font-weight: 400;
			color: #212224;

			@include tablet {
				font-size: 14px;
			}

			&:not(:last-child) {
				margin-bottom: 16px;

				@include tablet {
					margin-bottom: 8px;
				}
			}

			&:last-child {
				margin-bottom: 24px;
			}
		}
	}

	&__button {
		@include tablet {
			position: relative;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}
