@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

html {
	scroll-behavior: smooth;
}

body {
	// text-wrap: balance;
	font-family: "Inter", sans-serif;
	font-style: normal;
}

img {
	display: block;
}

a {
	text-decoration: none;

	&.text-link {
		color: $blue;
		text-decoration: underline;
		font-weight: 500;
	}
}
