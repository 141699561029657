@import "vars";

@mixin mediaBg() {
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@content;
	}
}

@mixin background-image($position: center, $repeat: no-repeat, $size: cover) {
	background-position: $position;
	background-repeat: $repeat;
	background-size: $size;
}

@mixin mobile-small() {
	@media screen and (max-width: $mobile-small) {
		@content;
	}
}

@mixin mobile() {
	@media screen and (max-width: $mobile) {
		@content;
	}
}

@mixin tablet() {
	@media screen and (max-width: $tablet) {
		@content;
	}
}

@mixin laptop-small() {
	@media screen and (max-width: $laptop-small) {
		@content;
	}
}

@mixin laptop() {
	@media screen and (max-width: $laptop) {
		@content;
	}
}

@mixin desktop() {
	@media screen and (max-width: $desktop) {
		@content;
	}
}
