.features {
	margin-bottom: 80px;

	@include tablet {
		margin-bottom: 40px;
	}

	&__wrapper {
	}

	&__header {
		margin-bottom: 40px;

		@include tablet {
			margin-bottom: 24px;
		}
	}

	&__title {
		text-align: center;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 2rem 4rem;

		@include laptop-small {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: 2.5rem;
		}

		@include tablet {
			grid-template-columns: repeat(1, minmax(0, 1fr));
			gap: 1rem;
		}
	}

	&-card {
		padding: 20px 24px;
		box-shadow: rgba(54, 96, 224, 0.15) 0px 2px 12px 0px;
		border-radius: 12px;

		@include tablet {
			display: flex;
			align-items: center;
			padding: 16px;
		}

		&__text {
			margin-top: 12px;

			@include tablet {
				margin-top: 0px;
				margin-left: 8px;
			}
		}

		&__title {
			color: $black;
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 6px;

			@include tablet {
				font-size: 14px;
				margin-bottom: 4px;
			}
		}

		&__subtitle {
			color: $black;
			font-size: 14px;
			font-weight: 400;

			@include tablet {
				font-size: 13px;
			}
		}
	}
}
