.mission {
	margin-top: 60px;
	margin-bottom: 80px;

	@include tablet {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	&__wrapper {
		margin: 0 auto;
		text-align: center;
		max-width: 740px;
		position: relative;
		border-radius: 30px;
		box-shadow: rgba(54, 96, 224, 0.15) 0px 2px 12px 0px;
		padding: 24px;

		&::after {
			content: "";
			width: 100px;
			height: 100px;
			background: url("../img/icons/quote.svg") center right / contain no-repeat;
			position: absolute;
			top: 0px;
			right: 10px;
			color: $blue;
			opacity: 0.2;
			z-index: -1;
		}
	}

	&__title {
		margin-bottom: 24px;

		@include tablet {
			margin-bottom: 18px;
		}
	}

	&__text {
		font-size: 16px;
		font-weight: 400;
		color: $black;

		@include tablet {
			font-size: 14px;
		}
	}
}
