.cookie-notification {
	position: fixed;
	right: 0px;
	bottom: 0px;
	z-index: 40;
	margin: 10px;
	max-width: 400px;
	border-radius: 12px;
	background-color: $white;
	padding: 16px 24px;
	border: 1px solid #e0e0e0;

	&__title {
		margin-bottom: 4px;
		font-size: 20px;
		font-weight: 500;
		color: rgb(2 16 50);
	}

	&__text {
		font-size: 14px;
		margin-bottom: 16px;
		color: rgb(2 16 50);

		a {
			text-decoration: underline;
			color: $blue;
		}
	}

	&__btn {
		width: 100%;
		padding: 16px 24px;
		font-size: 14px;
		color: $white;
		background-color: $blue;
		border-radius: 8px;
	}
}
